import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import { addGraceSubscription } from '../../services/subscriptions.service';
import TransmissionOrgan from '../form-elements/transmission-organ.component';
import Subscribers from '../form-elements/subscribers.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddGraceSubscription = ({ title, dataId, onCancel, onSuccess }) => {
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: '',
      transmissionOrganID: null,
      gracePeriod: 0,
      resetProgress: true,
    },
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [subscriberDetails, setSubscriberDetails] = useState({});
  const emailWatch = watch('email');

  const saveNewData = (payload) => {
    addGraceSubscription(payload)
      .then(() => {
        onSuccess('Grace period added successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      id: data.email.id || '',
      transmissionOrganID: data.transmissionOrganID || 0,
      gracePeriod: data.gracePeriod,
      resetProgress: data.resetProgress,
    };

    saveNewData(payload);
  };

  useEffect(() => {
    setSubscriberDetails(emailWatch);
  }, [emailWatch]);

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
    }
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="email"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Subscribers
                      id="email"
                      name="email"
                      label="Enter Subscriber Email"
                      defaultValue={value}
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      error={errors?.email?.message || ''}
                      disabled={!!dataId}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="transmissionOrganID"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <TransmissionOrgan
                      id="transmissionOrganID"
                      name="transmissionOrganID"
                      label="Select Transmission Organ"
                      defaultValue={value}
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      error={errors?.transmissionOrganID?.message || ''}
                      subscriberId={subscriberDetails.id}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="gracePeriod"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED_NUMBER,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Grace Period (in days)"
                      name="gracePeriod"
                      type="number"
                      error={!!errors.grace}
                      helperText={errors?.grace?.message || null}
                      fullWidth
                      inputProps={{ inputMode: 'numeric' }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={6} sx={{ mt: 1 }}>
              <Controller
                name="resetProgress"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="resetProgress"
                        onChange={onChange}
                        value={value}
                        defaultChecked={false}
                      />
                    }
                    label="Reset transmission video play progress"
                  />
                )}
              />
            </Grid>
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="add-edit-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddGraceSubscription.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default AddGraceSubscription;
