import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useTheme } from '@emotion/react';
import { Box, IconButton, Stack, TextField, Typography } from '@mui/material';
import Comments from './comments.component';
import Iconify from '../common/iconify.component';
import {
  getCommentsByVideoId,
  checkNewCommentsByVideoId,
  postCommentByVideoId,
  deleteCommentByVideoId,
} from '../../services/content.service';
import { formatGmtStringToLocale } from '../../utils/datetime.util';
import { maxLengthValidation } from '../../utils/validations.util';

const CommentsConversation = ({ selectedVideo, adminUser }) => {
  const theme = useTheme();
  const [comments, setComments] = useState([]);
  const commentsRef = useRef([]);
  const {
    control,
    formState: { errors },
    // handleSubmit,
  } = useForm({
    defaultValues: {
      comment: '',
    },
  });

  let interval;

  const getComments = (
    fromCommentId = 0,
    toCommentId = 0,
    direction = 'prev'
  ) => {
    let uri = `?videoId=${selectedVideo.videoId}`;
    uri += `&fromCommentId=${fromCommentId}`;
    uri += `&toCommentId=${toCommentId}`;
    uri += `&direction=${direction}`;
    uri += `&adminSubscriberId=${adminUser}`;

    getCommentsByVideoId(uri)
      .then((res) => {
        const groupedResponse = {};
        const commentsResponse = [];

        res.data.comments.forEach((el) => {
          const date = formatGmtStringToLocale(el.createdAt, 'DD MMMM YYYY');
          if (groupedResponse[date]) {
            groupedResponse[date].push(el);
          } else {
            groupedResponse[date] = [el];
          }
        });

        const generateLink = {
          type: 'link',
          from: 0,
          to: res.data.requested.to,
          direction: res.data.requested.direction,
          btnText: 'View Previous Comments',
        };

        if (res.data.loadMore && res.data.requested.direction === 'prev') {
          generateLink.from = res.data.comments[0].id;
          commentsResponse.push(generateLink);
        }

        Object.entries(groupedResponse).forEach(([key, value]) => {
          commentsResponse.push({
            type: 'date',
            text: key,
          });

          value.forEach((el) => {
            commentsResponse.push({
              type: 'comment',
              id: el.id,
              comment: el.comment,
              isAdmin: el.subscriberId === adminUser,
              createdAt: formatGmtStringToLocale(el.createdAt, 'hh:mm A'),
              firstName: res.data.subscribers[el.subscriberId].firstName,
              lastName: res.data.subscribers[el.subscriberId].lastName,
              profilePic: res.data.subscribers[el.subscriberId].profilePic,
            });
          });
        });

        // =====================================================================

        // if (commentsResponse.length > 0) {
        //   const index = comments.findIndex(
        //     (obj) =>
        //       obj.type === 'link' &&
        //       obj.direction === res.data.requested.direction &&
        //       Number(obj.from) === Number(res.data.requested.from) &&
        //       Number(obj.to) === Number(res.data.requested.to)
        //   );

        //   if (
        //     index > -1 &&
        //     comments[index + 1] &&
        //     comments[index + 1].type === 'comment' &&
        //     res.data.requested.direction === 'next'
        //   ) {
        //     commentsResponse[commentsResponse.length - 1].to =
        //       comments[index + 1].commId;
        //   }

        //   if (index === -1) {
        //     setComments(commentsResponse);
        //     commentsRef.current = commentsResponse;
        //   } else {
        //     let part1 = [];
        //     let part2 = [];

        //     if (index === 0) {
        //       part1 = [];
        //       part2 = comments.slice(index + 1);
        //     } else {
        //       part1 = comments.slice(0, index);
        //       part2 = comments.slice(index + 1);
        //     }

        //     setComments([...part1, ...commentsResponse, ...part2]);
        //     commentsRef.current = [...part1, ...commentsResponse, ...part2];
        //   }
        // }

        // =====================================================================

        setComments(commentsResponse);
        commentsRef.current = commentsResponse;
      })
      .catch(() => {});
  };

  const checkNewComments = () => {
    const lastCommentId =
      commentsRef.current.length !== 0
        ? commentsRef.current.findLast((el) => el.type === 'comment').id
        : 0;

    let uri = `?videoId=${selectedVideo.videoId}`;
    uri += `&commentId=${lastCommentId}`;

    checkNewCommentsByVideoId(uri).then((res) => {
      if (res.data.newComments) {
        clearInterval(interval);

        const generateLink = {
          type: 'link',
          from: lastCommentId,
          to: 0,
          direction: 'next',
        };

        setComments([...comments, generateLink]);
        commentsRef.current = [...comments, generateLink];
      }
    });
  };

  // eslint-disable-next-line no-unused-vars
  const postComment = (e) => {
    e.preventDefault();

    const payload = {
      id: selectedVideo.videoId,
      comment: 'comment',
    };

    postCommentByVideoId(payload).then((res) => {
      if (res.data.id) {
        const postData = {
          type: 'comment',
          id: res.data.id,
          comment: res.data.comment,
          isAdmin: res.data.subscriberId === adminUser,
          createdAt: formatGmtStringToLocale(res.data.createdAt, 'hh:mm A'),
          firstName: res.data.subscribers[res.data.subscriberId].firstName,
          lastName: res.data.subscribers[res.data.subscriberId].lastName,
          profilePic: res.data.subscribers[res.data.subscriberId].profilePic,
        };

        setComments([...comments, postData]);
        commentsRef.current = [...comments, postData];
      }
    });
  };

  // eslint-disable-next-line no-unused-vars
  const deleteComment = (id) => {
    const uri = `?videoId=${selectedVideo.videoId}`;

    deleteCommentByVideoId(id, uri).then((res) => {
      if (res.data.success) {
        const deletedArr = comments.filter((el) => el.id !== id);
        setComments(deletedArr);
        commentsRef.current = deletedArr;
      }
    });
  };

  useEffect(() => {
    setComments([]);
    commentsRef.current = [];

    clearInterval(interval);
    getComments();

    interval = setInterval(() => {
      checkNewComments();
    }, 10000);

    return () => clearInterval(interval);
  }, [selectedVideo]);

  return (
    <Stack height="100%" maxHeight="100vh" width="auto">
      <Box
        p={2}
        sx={{
          width: '100%',
          backgroundColor: '#F8FAFF',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%', height: '100%' }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            {selectedVideo.thumbnail ? (
              <Box
                component="img"
                sx={{
                  objectFit: 'cover',
                  width: 64,
                  height: 36,
                  bgcolor: '#EDEDED',
                  borderRadius: '5px',
                }}
                alt={selectedVideo.title}
                src={selectedVideo.thumbnail}
              />
            ) : (
              <Box
                sx={{
                  width: 64,
                  height: 36,
                  bgcolor: '#808080',
                  borderRadius: '5px',
                }}
              />
            )}

            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
                fontSize: '14px',
              }}
            >
              {selectedVideo.title}
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={3}>
            <IconButton>
              <Iconify icon="fe:search" />
            </IconButton>
          </Stack>
        </Stack>
      </Box>

      <Box
        className="scrollbar"
        width="100%"
        sx={{
          flexGrow: 1,
          height: '100%',
          overflowY: 'auto',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        }}
      >
        <Comments comments={comments} />
      </Box>

      <Box
        p={2}
        sx={{
          width: '100%',
          backgroundColor:
            theme.palette.mode === 'light'
              ? '#F8FAFF'
              : theme.palette.background.paper,
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        }}
      >
        <Stack direction="row" alignItems="center" spacing={3}>
          <Stack sx={{ width: '100%' }}>
            <Box
              sx={{
                display: 'none',
                zIndex: 10,
                position: 'fixed',
                bottom: 81,
                right: 100,
              }}
            />
            <Controller
              name="comment"
              control={control}
              rules={{ ...maxLengthValidation(255) }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Post your comment..."
                  placeholder="Post your comment..."
                  type="text"
                  error={!!errors.comment}
                  helperText={errors?.comment?.message || null}
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                />
              )}
            />
          </Stack>

          <Box
            sx={{
              height: 20,
              width: 20,
              borderRadius: 1.5,
            }}
          >
            <Stack
              sx={{
                height: '100%',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <IconButton>
                <Iconify
                  icon="ph:paper-plane-tilt-bold"
                  sx={{ color: theme.palette.primary.main }}
                />
              </IconButton>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};

CommentsConversation.propTypes = {
  selectedVideo: PropTypes.object.isRequired,
  adminUser: PropTypes.number.isRequired,
};

export default CommentsConversation;
