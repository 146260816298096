import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Slide,
} from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import { CONTENT_ALIAS } from '../../config/const.config';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ListSequenceVideos = ({
  handleOnDragEndVideos,
  handleUpdateVideosSequence,
  action,
  selectedCategory,
  categoryVideosList,
  vidOptions,
  handleCloseDialog,
}) => (
  <Dialog
    open={action.show}
    aria-describedby="category-videos"
    aria-labelledby="category-videos"
    TransitionComponent={Transition}
    fullWidth
    maxWidth="md"
  >
    <DialogTitle>{`Reorder ${CONTENT_ALIAS.VIDEO_PLURAL} Sequence - ${selectedCategory.categoryName}`}</DialogTitle>

    <DialogContent dividers>
      {vidOptions.loading && <Loading />}

      {!vidOptions.loading && (
        <DragDropContext onDragEnd={handleOnDragEndVideos}>
          <Droppable droppableId="videos">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {!vidOptions.loading &&
                  categoryVideosList.length > 0 &&
                  categoryVideosList.map((video, index) => (
                    <Draggable
                      key={`video-${video.id}`}
                      draggableId={video.id.toString()}
                      index={index}
                    >
                      {(provide) => (
                        <Card
                          sx={{
                            marginBottom: '5px',
                          }}
                          ref={provide.innerRef}
                          {...provide.draggableProps}
                          {...provide.dragHandleProps}
                        >
                          <CardContent
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              paddingY: '12px',
                              '&:last-child': {
                                paddingBottom: '12px',
                              },
                            }}
                          >
                            <div>{video.title}</div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                textAlign: 'center',
                              }}
                            >
                              <div>
                                {video.videoType}
                                {video.transmissionOrgan
                                  ? ` (${video.transmissionOrgan})`
                                  : ''}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  marginLeft: 20,
                                }}
                              >
                                <Iconify icon="ic:round-table-rows" />
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      )}
                    </Draggable>
                  ))}

                {!vidOptions.loading && categoryVideosList.length === 0 && (
                  <Paper
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    No data available.
                  </Paper>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </DialogContent>

    <DialogActions>
      <Button
        onClick={handleUpdateVideosSequence}
        disabled={
          categoryVideosList.length === 0 || categoryVideosList.length === 1
        }
      >
        Save
      </Button>
      <Button onClick={handleCloseDialog}>Cancel</Button>
    </DialogActions>
  </Dialog>
);

ListSequenceVideos.propTypes = {
  handleOnDragEndVideos: PropTypes.func.isRequired,
  handleUpdateVideosSequence: PropTypes.func.isRequired,
  action: PropTypes.object.isRequired,
  selectedCategory: PropTypes.object.isRequired,
  categoryVideosList: PropTypes.array.isRequired,
  vidOptions: PropTypes.object.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
};

export default ListSequenceVideos;
