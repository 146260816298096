import axios from 'axios';
import { getDeviceId } from '../utils/device-info.util';
import { getLocalValue } from '../utils/local-cache.util';
import { LS_ADMIN_USER_AUTH_TOKEN } from '../config/const.config';

const prepareRequestOptions = (
  url,
  method,
  data = null,
  contentType = false
) => {
  const baseAPIUrl = process.env.REACT_APP_CMS_API_ENDPOINT;
  const uuid = getDeviceId();
  const adminAuth = getLocalValue(LS_ADMIN_USER_AUTH_TOKEN);

  const options = {
    url: `${baseAPIUrl}${url}`,
    method,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
    headers: {
      'Content-Type': !contentType ? 'application/json' : 'multipart/form-data',
      'X-Req-Device': uuid,
      'X-Admin-Auth-Token': adminAuth,
    },
    data: !contentType ? JSON.stringify(data || {}) : data,
  };

  return options;
};

export const getDataList = async (type) => {
  const options = prepareRequestOptions(`/admin/list/${type}`, 'GET');

  const response = await axios(options);
  return response.data;
};

export const getSearchListChannels = async () => {
  const options = prepareRequestOptions('/admin/channels/search-list', 'GET');

  const response = await axios(options);
  return response.data;
};

export const createNewCategory = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/category/create',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const viewCategoryById = async (id, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/category/${id}/view/${type}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const updateCategoryById = async (id, payload, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/category/${id}/update/${type}`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const getSearchListCategories = async () => {
  const options = prepareRequestOptions('/admin/categories/search-list', 'GET');
  const response = await axios(options);
  return response.data;
};

export const getSearchListTestimonialCategories = async () => {
  const options = prepareRequestOptions(
    '/admin/testimonials/category/search-list',
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const getSearchListTestimonialTabs = async () => {
  const options = prepareRequestOptions(
    '/admin/testimonials/tab/search-list',
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const createNewTransmissionOrgan = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/transmission-organ/create',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const viewTransmissionOrganById = async (id, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/transmission-organ/${id}/view/${type}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const updateTransmissionOrganById = async (
  id,
  payload,
  type = 'orig'
) => {
  const options = prepareRequestOptions(
    `/admin/transmission-organ/${id}/update/${type}`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const getSearchListTransmissionOrgans = async () => {
  const options = prepareRequestOptions(
    '/admin/tramsmission-organs/search-list',
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const getSubscribedTransmissionOrgans = async (id) => {
  const options = prepareRequestOptions(
    `/admin/tramsmission-organs/${id}/subscribed`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const getProgramTransmissionOrgans = async (id) => {
  const options = prepareRequestOptions(
    `/admin/tramsmission-organs/${id}/category`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const createNewVideo = async (payload) => {
  const options = prepareRequestOptions('/admin/video/create', 'POST', payload);

  const response = await axios(options);
  return response.data;
};

export const viewVideoById = async (id, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/video/${id}/view/${type}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const viewVideoAssetById = async (id, language) => {
  const options = prepareRequestOptions(
    `/admin/video/${id}/assets/${language}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const updateVideoById = async (id, payload, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/video/${id}/update/${type}`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const deleteVideoById = async (id) => {
  const options = prepareRequestOptions(`/admin/video/${id}/delete`, 'DELETE');

  const response = await axios(options);
  return response.data;
};

export const assignCategoryToVideo = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/video/${id}/category/assign`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const getSearchListVideos = async () => {
  const options = prepareRequestOptions('/admin/videos/search-list', 'GET');

  const response = await axios(options);
  return response.data;
};

export const syncVideoCDNStatus = async () => {
  const options = prepareRequestOptions(`/admin/video/videos/cdn/sync`, 'GET');

  const response = await axios(options);
  return response.data;
};

export const getListVideosByCategoryId = async (categoryId, params) => {
  const options = prepareRequestOptions(
    `/admin/video/${categoryId}/list${params}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const getListSequence = async (query) => {
  const options = prepareRequestOptions(`/admin/sequence/list/${query}`, 'GET');

  const response = await axios(options);
  return response.data;
};

export const updateChannelCategoryVideosSequence = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/sequence/video-category/update',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const updateTabsSequence = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/sequence/tabs/update',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const createNewTab = async (payload) => {
  const options = prepareRequestOptions('/admin/tab/create', 'POST', payload);

  const response = await axios(options);
  return response.data;
};

export const viewTabById = async (id) => {
  const options = prepareRequestOptions(`/admin/tab/${id}/view`, 'GET');

  const response = await axios(options);
  return response.data;
};

export const updateTabById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/tab/${id}/update`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const deleteTabById = async (id) => {
  const options = prepareRequestOptions(`/admin/tab/${id}/delete`, 'DELETE');

  const response = await axios(options);
  return response.data;
};

export const getPlayableStream = async (uri) => {
  const options = prepareRequestOptions(
    `/admin/get/playable-stream?${uri}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const uploadAssetFile = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/upload/file/cdn`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const uploadVideoToBunnyCDN = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/upload/video/cdn`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const uploadImageToBunnyCDN = async (payload, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/upload/image/cdn/${type}`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const uploadSrtToBunnyCDN = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/upload/srt/cdn`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const deleteSrtToBunnyCDN = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/delete/srt/cdn`,
    'DELETE',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const viewCaptionsById = async (videoId) => {
  const options = prepareRequestOptions(
    `/admin/list/captions/${videoId}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const toggleStatus = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/${payload.type}/status/toggle`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const createNewTestimonialTab = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/testimonials/tab/create',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const viewTestimonialTabById = async (id, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/testimonials/tab/${id}/view/${type}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const updateTestimonialTabById = async (id, payload, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/testimonials/tab/${id}/update/${type}`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const deleteTestimonialTabById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/testimonials/tab/${id}/delete`,
    'DELETE'
  );

  const response = await axios(options);
  return response.data;
};

// testimonial category
export const createNewTestimonialCategory = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/testimonials/category/create',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const viewTestimonialCategoryById = async (id, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/testimonials/category/${id}/view/${type}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const updateTestimonialCategoryById = async (
  id,
  payload,
  type = 'orig'
) => {
  const options = prepareRequestOptions(
    `/admin/testimonials/category/${id}/update/${type}`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const deleteTestimonialCategoryById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/testimonials/category/${id}/delete`,
    'DELETE'
  );

  const response = await axios(options);
  return response.data;
};

// text testimonial
export const createNewTestimonialText = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/testimonials/text/create',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const viewTestimonialTextById = async (id, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/testimonials/text/${id}/view/${type}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const updateTestimonialTextById = async (id, payload, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/testimonials/text/${id}/update/${type}`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const deleteTestimonialTextById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/testimonials/text/${id}/delete`,
    'DELETE'
  );

  const response = await axios(options);
  return response.data;
};

export const getCommentsByVideoId = async (params) => {
  const options = prepareRequestOptions(
    `/admin/videos/comments${params}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const checkNewCommentsByVideoId = async (params) => {
  const options = prepareRequestOptions(
    `/admin/videos/check-new-comments${params}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const postCommentByVideoId = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/videos/post-comment',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const deleteCommentByVideoId = async (id, params) => {
  const options = prepareRequestOptions(
    `/admin/videos/delete-comment/${id}${params}`,
    'DELETE'
  );

  const response = await axios(options);
  return response.data;
};
