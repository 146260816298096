// Define cell data type
const CELL_DATA_TYPES = {
  TEXT: 'text',
  EMAIL: 'email',
  NUMBER: 'number',
  DATE: 'date',
  DATETIME: 'datetime',
  TIMESTAMP: 'timestamp',
  DURATION: 'duration',
  STATUS: 'status',
  SWITCH: 'switch',
  PLATFORM: 'platform',
  MONEY: 'money',
  PERCENTAGE: 'percent',
  BOOL: 'boolean',
  CODE: 'code',
  PREVIEW: 'preview',
  IMAGE: 'image',
  PROGRESS: 'progress',
  PROFILE: 'profile',
};

export default CELL_DATA_TYPES;
