import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Subscribers module configurations
export const LISTING_COLUMNS = [
  {
    id: 'profilePic',
    label: '',
    width: '2%',
    align: 'center',
    dataKey: 'profilePic',
    sortable: false,
    type: CELL_DATA_TYPES.PROFILE,
  },
  {
    id: 'firstName',
    label: 'First Name',
    width: '10%',
    align: 'left',
    dataKey: 'firstName',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    width: '10%',
    align: 'left',
    dataKey: 'lastName',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'email',
    label: 'Email',
    width: '20%',
    align: 'left',
    dataKey: 'email',
    sortable: true,
    type: CELL_DATA_TYPES.EMAIL,
  },
  {
    id: 'contact',
    label: 'Contact',
    width: '20%',
    align: 'left',
    dataKey: 'contact',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'signupTime',
    label: 'Signup Date',
    width: '15%',
    align: 'left',
    dataKey: 'signupTime',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'signupFromPlatform',
    label: 'Signup From',
    width: '10%',
    align: 'left',
    dataKey: 'signupFromPlatform',
    sortable: true,
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.STATUS,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.MANAGE_SUBSCRIPTIONS.value,
    label: ACTIONS.MANAGE_SUBSCRIPTIONS.label,
  },
  {
    action: ACTIONS.CHANGE_PASSWORD.value,
    label: ACTIONS.CHANGE_PASSWORD.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'firstName',
    label: 'First Name',
    dataKey: 'firstName',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    dataKey: 'lastName',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'email',
    label: 'Email',
    dataKey: 'email',
    type: CELL_DATA_TYPES.EMAIL,
  },
  {
    id: 'contact',
    label: 'Contact',
    dataKey: 'contact',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'signupTime',
    label: 'Signup Date',
    dataKey: 'signupTime',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'signupFromPlatform',
    label: 'Signup From',
    dataKey: 'signupFromPlatform',
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'role',
    label: 'Role',
    dataKey: 'role',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'allowViewComments',
    label: 'Allow View Comments',
    dataKey: 'allowViewComments',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'allowPostComment',
    label: 'Allow Post Comment',
    dataKey: 'allowPostComment',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'notes',
    label: 'Notes',
    dataKey: 'notes',
    type: CELL_DATA_TYPES.TEXT,
  },
];
