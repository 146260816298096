import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const getCurrentTimestamp = () => Math.ceil(Date.now() / 1000);

export const getDate = (days = 0) => {
  const date = new Date();
  if (days) {
    date.setDate(date.getDate() + days);
  }
  return date;
};

export const formatDate = (inputDate, format = 'YYYY-MM-DD') => {
  if (!inputDate) return '';

  return dayjs(inputDate).format(format);
};

export const formatTimestamp = (inputTimestamp, format = 'YYYY-MM-DD') => {
  if (!inputTimestamp) return '';

  return dayjs(inputTimestamp * 1000).format(format);
};

export const formatDateToUtc = (inputDate) => {
  if (!inputDate) return '';

  return dayjs(inputDate).utc().format();
};

export const formatTimestampToUtc = (inputTimestamp) => {
  if (!inputTimestamp) return '';

  return dayjs(inputTimestamp * 1000)
    .utc()
    .format();
};

export const formatGmtStringToLocale = (inputGmtString, format = '') => {
  if (!inputGmtString) return '';

  return dayjs(inputGmtString).format(format);
};
