import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, Container, Paper } from '@mui/material';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  getListSequence,
  updateChannelCategoryVideosSequence,
} from '../../services/content.service';
import { CONTENT_ALIAS } from '../../config/const.config';
import ListSequenceVideos from './list-sequence-videos.component';
import Loading from '../table-elements/loading.component';

const ListSequence = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [categoryVideosList, setCategoryVideosList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    id: null,
    categoryName: '',
  });
  const [catOptions, setCatOptions] = useState({
    error: false,
    loading: true,
  });
  const [vidOptions, setVidOptions] = useState({
    error: false,
    loading: true,
  });
  const [action, setAction] = useState({
    show: false,
    data: '',
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleOnDragEndVideos = (result) => {
    if (result.destination) {
      const newVideos = Array.from(categoryVideosList);
      const [draggedItem] = newVideos.splice(result.source.index, 1);

      newVideos.splice(result.destination.index, 0, draggedItem);
      setCategoryVideosList(newVideos);
    }
  };

  const handleCloseDialog = () => {
    setAction({ ...action, show: false, data: '' });
    setVidOptions({ ...vidOptions, loading: true });
  };

  const handleUpdateVideosSequence = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const updatedVideosCategorySequence = categoryVideosList.map(
      (video) => video.video_id
    );

    const payload = {
      channelID: categoryVideosList[0].channel_id,
      categoryID: selectedCategory.id,
      newSequence: updatedVideosCategorySequence,
    };

    updateChannelCategoryVideosSequence(payload)
      .then((res) => {
        if (res?.data?.success) {
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'success',
            message: `${CONTENT_ALIAS.VIDEO_PLURAL} sequence updated successfully.`,
          });
          handleCloseDialog();
        }
      })
      .catch(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: 'Something went wrong.',
        });
      });
  };

  const handleVideoReordering = (category) => {
    setAction({ ...action, show: true, data: category.id });
    setSelectedCategory({
      ...selectedCategory,
      id: category.id,
      categoryName: category.name,
    });

    let uri = 'video-category';
    uri += `?categoryID=${category.id}`;

    getListSequence(uri)
      .then((res) => {
        setVidOptions({
          ...vidOptions,
          loading: false,
          error: false,
        });
        setCategoryVideosList(res.data);
      })
      .catch(() =>
        setVidOptions({
          ...vidOptions,
          loading: false,
          error: true,
        })
      );
  };

  const getSequenceData = () => {
    const uri = 'category';

    getListSequence(uri)
      .then((res) => {
        setCatOptions({
          ...catOptions,
          loading: false,
          error: false,
        });
        setCategoryList(res.data);
      })
      .catch(() => {
        setCatOptions({
          ...catOptions,
          loading: false,
          error: true,
        });
        setCategoryList([]);
      });
  };

  useEffect(() => {
    getSequenceData();
  }, []);

  return (
    <>
      {catOptions.loading && <Loading />}

      <Container
        maxWidth="false"
        sx={{ maxHeight: '74vh', overflowY: 'auto', paddingX: 0 }}
      >
        {!catOptions.loading &&
          categoryList.length > 0 &&
          categoryList.map((category) => (
            <Card
              sx={{
                marginBottom: '5px',
              }}
              key={`category-${category.id}`}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingY: '12px',
                  '&:last-child': {
                    paddingBottom: '12px',
                  },
                }}
              >
                <div>{category.name}</div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                    color="inherit"
                    onClick={() => handleVideoReordering(category)}
                  >
                    {`Reorder ${CONTENT_ALIAS.VIDEO_PLURAL} Sequence`}
                  </Button>
                </div>
              </CardContent>
            </Card>
          ))}

        {!catOptions.loading && categoryList.length === 0 && (
          <Paper
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '10vh',
            }}
          >
            No data available.
          </Paper>
        )}

        {action.show && (
          <ListSequenceVideos
            handleOnDragEndVideos={handleOnDragEndVideos}
            handleUpdateVideosSequence={handleUpdateVideosSequence}
            action={action}
            selectedCategory={selectedCategory}
            categoryVideosList={categoryVideosList}
            vidOptions={vidOptions}
            handleCloseDialog={handleCloseDialog}
          />
        )}
      </Container>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

ListSequence.propTypes = {};

export default ListSequence;
