import React, { useEffect, useRef, useState } from 'react';
import {
  Card,
  Stack,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { ACTIONS } from '../../config/const.config';
import {
  LISTING_ACTIONS,
  LISTING_COLUMNS,
} from '../../config/module-configs/subscriptions.config';
import ListData from '../table-elements/list-data.component';
import {
  getListData,
  deleteDataById,
} from '../../services/subscriptions.service';
import Iconify from '../common/iconify.component';
import SnackbarInfo from '../common/snackbar-info.component';
import { toggleStatus } from '../../services/content.service';
import ConfirmPopup from '../common/confirm-popup.component';
import ViewSubscription from './view-subscription.component';
import AddEditSubscription from './add-edit-subscription.component';
import AddGraceSubscription from './add-grace-subscription.component';

const ListSubscription = () => {
  const columns = LISTING_COLUMNS;
  const actions = LISTING_ACTIONS;

  const searchSubscriptionsRef = useRef(null);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    q: null,
    error: false,
    loading: true,
    searchValue: '',
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleToggleStatus = (r, value) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      id: r.id,
      status: value ? 0 : 1,
      type: 'subscription',
    };

    toggleStatus(payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'Status updated.',
        });
      })
      .catch((e) => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: e.response?.data?.message,
        });
      });
  };

  const handleActionSelect = (selectedAction, selectedData) => {
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };
  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }

    handleActionCancel();
    handleRefreshData();
  };

  const handleDeleteData = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    deleteDataById(doAction.data.id)
      .then(() => {
        handleActionSuccess('Subscription deleted successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        handleActionCancel();
      });
  };

  const handleSearchChange = (e) => {
    setOptions({
      ...options,
      searchValue: e.target.value || '',
    });
  };

  const handleSearchVideos = () => {
    setOptions({
      ...options,
      q: searchSubscriptionsRef.current.value,
      reloadCounter: options.reloadCounter + 1,
      page: 0,
    });
  };

  useEffect(() => {
    let params = `?page=${options.page + 1}`;
    params += `&perPage=${options.rowsPerPage}`;
    if (options.sortBy && options.sortOrder) {
      params += `&sortBy=${options.sortBy}`;
      params += `&sortOrder=${options.sortOrder}`;
    }
    if (options.q) {
      params += `&q=${options.q}`;
    }

    getListData(params)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  }, [options.reloadCounter]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <div>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Iconify icon="ic:round-add" />}
            onClick={() => handleActionSelect(ACTIONS.CREATE.value, null)}
          >
            Create New
          </Button>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Iconify icon="wpf:renew-subscription" />}
            sx={{
              marginLeft: '5px',
            }}
            onClick={() =>
              handleActionSelect(ACTIONS.GRACE_SUBSCRIPTION.value, null)
            }
          >
            Add Grace Subscription
          </Button>

          <TextField
            label="Search Subscriptions"
            type="text"
            inputRef={searchSubscriptionsRef}
            onChange={handleSearchChange}
            sx={{
              width: '210px',
              marginLeft: '5px',
              '.MuiInputLabel-root.MuiInputLabel-shrink.MuiFormLabel-filled': {
                top: '0px',
              },
              '.MuiInputLabel-root': {
                top: '-8px',
              },
            }}
            InputProps={{
              style: {
                height: '2.25em',
              },
              endAdornment: (
                <InputAdornment position="end" onClick={handleSearchVideos}>
                  <IconButton edge="end" color="primary">
                    <Iconify icon="fe:search" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: !(
                searchSubscriptionsRef.current?.value === '' ||
                searchSubscriptionsRef.current === null
              ),
            }}
          />
        </div>
        <div>
          <Button
            color="inherit"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
          >
            Refresh
          </Button>
        </div>
      </Stack>

      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={actions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          handleToggleStatus={handleToggleStatus}
          onAction={handleActionSelect}
        />
      </Card>

      {doAction.action === ACTIONS.CREATE.value && (
        <AddEditSubscription
          title="Create New Subscription"
          dataId={0}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.EDIT.value && (
        <AddEditSubscription
          title="Edit Subscription Details - "
          user={doAction.data}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.VIEW.value && (
        <ViewSubscription
          title="Subscription Details - "
          user={doAction.data}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
        />
      )}

      {doAction.action === ACTIONS.DELETE.value && (
        <ConfirmPopup
          title="Delete Subscriber"
          message={`Do you want to delete '${doAction.data.email}' subscription? You can not undo this action!`}
          onCancel={handleActionCancel}
          onSuccess={handleDeleteData}
        />
      )}

      {doAction.action === ACTIONS.GRACE_SUBSCRIPTION.value && (
        <AddGraceSubscription
          title="Add Grace Subscription"
          dataId={0}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

ListSubscription.propTypes = {};

export default ListSubscription;
