import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListSequence from '../../components/content/list-sequence.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const ContentSequenceView = () => {
  const pageTitle = PAGE_TITLE_LIST.CONTENT_SEQUENCE_VIDEOS;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListSequence />
    </AppLayout>
  );
};

ContentSequenceView.propTypes = {};

export default ContentSequenceView;
