import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Grid,
  Divider,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  viewTestimonialCategoryById,
  updateTestimonialCategoryById,
} from '../../services/content.service';
import { LANGUAGES } from '../../config/const.config';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const TranslateTestimonialCategory = ({
  title,
  dataId,
  onCancel,
  onSuccess,
}) => {
  const defaultValues = {};
  for (let i = 0; i < LANGUAGES.length; i += 1) {
    defaultValues[`name_${LANGUAGES[i].value}`] = '';
  }

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues,
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const updateExistingData = (payload) => {
    updateTestimonialCategoryById(dataId, payload, 'lang')
      .then(() => {
        onSuccess('Testimonial Category details updated successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {};
    for (let i = 0; i < LANGUAGES.length; i += 1) {
      payload[`name_${LANGUAGES[i].value}`] =
        data[`name_${LANGUAGES[i].value}`];
    }

    if (dataId) {
      updateExistingData(payload);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewTestimonialCategoryById(dataId, 'lang')
      .then((res) => {
        // Fill form values
        for (let i = 0; i < LANGUAGES.length; i += 1) {
          if (res.data[`${LANGUAGES[i].value}`]) {
            setValue(
              `name_${LANGUAGES[i].value}`,
              res.data[`${LANGUAGES[i].value}`].name || ''
            );
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            {LANGUAGES.map((item, index) => (
              <Grid container spacing={2} key={`mn${item.value}`}>
                <Grid item xs={12} sm={12} md={12}>
                  <Divider
                    textAlign="center"
                    variant="fullWidth"
                    sx={{
                      fontWeight: 'bold',
                      mt: index === 0 ? 0 : 2,
                    }}
                  >
                    {item.label} Translation
                  </Divider>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Controller
                    name={`name_${item.value}`}
                    control={control}
                    rules={{
                      ...validationRules.REQUIRED,
                      ...{ ...maxLengthValidation(255) },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={`Category Name (${item.value})`}
                        type="text"
                        error={!!errors[`name_${item.value}`]}
                        helperText={
                          errors[`name_${item.value}`]?.message || null
                        }
                        fullWidth
                        inputProps={{ maxLength: 255 }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ))}
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="add-edit-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

TranslateTestimonialCategory.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default TranslateTestimonialCategory;
