import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import {
  Box,
  List,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListSubheader,
  Collapse,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ROUTE_PATH from '../../../config/routes.config';
import Iconify from '../../common/iconify.component';
import {
  CONTENT_ALIAS,
  FOCUS_PALETTE_BGCOLOR,
  SHOW_ADMIN_USERS,
  SHOW_CONTENT_CATEGORIES,
  SHOW_CONTENT_SEQUENCE,
  SHOW_CONTENT_TRANSMISSION_ORGANS,
  SHOW_CONTENT_VIDEOS,
  SHOW_NOTIFICATIONS,
  SHOW_OVERVIEW_DASHBOARD,
  SHOW_REALTIME_DASHBOARD,
  SHOW_REPORTS_LIVE_USERS,
  SHOW_REPORTS_OVERVIEW_VIDEOS,
  SHOW_REPORTS_SUBSCRIPTIONS,
  SHOW_REPORTS_WATCH_SESSIONS,
  SHOW_SALUTATION,
  SHOW_SUBSCRIBERS,
  SHOW_SUBSCRIPTIONS,
  SHOW_VIDEO_COMMENTS,
} from '../../../config/const.config';
import palette from '../../../theme/palette';

const StyledSubHeader = styled((props) => <ListSubheader {...props} />)(
  ({ theme }) => ({
    background: 'transparent',
    color: theme.palette.grey[200],
  })
);

const StyledNavItem = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

const StyledNavItemIcon = styled(ListItemIcon)({
  width: 24,
  height: 24,
  color: palette.background.paper,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 24,
});

const NavItem = ({ title, path, icon, subNav }) => (
  <StyledNavItem
    component={RouterLink}
    to={path}
    sx={{
      '&.active': {
        color: palette.common.white,
        bgcolor: FOCUS_PALETTE_BGCOLOR,
        fontWeight: 'fontWeightBold',
      },
      height: subNav ? 36 : 48,
    }}
  >
    {!subNav && <StyledNavItemIcon>{icon}</StyledNavItemIcon>}
    <ListItemText
      disableTypography
      primary={title}
      sx={{
        color: palette.common.white,
        pl: subNav ? 6 : 0,
        overflowX: 'clip',
        textWrap: 'nowrap',
        textOverflow: 'ellipsis',
        paddingRight: 2,
      }}
    />
  </StyledNavItem>
);
NavItem.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.element,
  subNav: PropTypes.bool,
};
NavItem.defaultProps = {
  icon: <div />,
  subNav: false,
};

const NavGroup = ({ title, routePath, icon, navGroup }) => {
  const urlParts = window.location.href.split('/');
  const routePathParts = routePath.split('/');

  const initOpen =
    urlParts[urlParts.length - 2] === routePathParts[routePathParts.length - 1];

  const [open, setOpen] = useState(initOpen);
  const handleClick = () => setOpen(!open);

  return (
    <>
      <StyledNavItem onClick={handleClick}>
        <StyledNavItemIcon>{icon}</StyledNavItemIcon>
        <ListItemText
          disableTypography
          primary={title}
          sx={{
            color: palette.common.white,
            overflowX: 'clip',
            textWrap: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        />
        <StyledNavItemIcon>
          <Iconify icon={open ? 'mdi:menu-up' : 'mdi:menu-down'} />
        </StyledNavItemIcon>
      </StyledNavItem>

      <Collapse in={open} timeout="auto">
        <List disablePadding sx={{ p: 1 }}>
          {navGroup.map(
            (nav) =>
              nav.status === 1 && (
                <NavItem
                  key={nav.title}
                  title={nav.title}
                  path={`${routePath}/${nav.slug}`}
                  icon={<div />}
                  subNav
                />
              )
          )}
        </List>
      </Collapse>
    </>
  );
};
NavGroup.propTypes = {
  title: PropTypes.string.isRequired,
  routePath: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  navGroup: PropTypes.array.isRequired,
};

const NavItems = () => (
  <Box>
    <List disablePadding sx={{ p: 1 }}>
      {SHOW_OVERVIEW_DASHBOARD && (
        <NavItem
          title="Overview Dashboard"
          path={ROUTE_PATH.DASHBOARD}
          icon={<Iconify icon="ic:round-dashboard" />}
        />
      )}
      {SHOW_REALTIME_DASHBOARD && (
        <NavItem
          title="Live Users (Per Platform)"
          path={ROUTE_PATH.REALTIME_DASHBOARD}
          icon={<Iconify icon="tabler:activity-heartbeat" />}
        />
      )}
      {SHOW_SUBSCRIBERS && (
        <NavItem
          title="Subscribers"
          path={ROUTE_PATH.SUBSCRIBERS}
          icon={<Iconify icon="ci:users" />}
        />
      )}
      {SHOW_SUBSCRIPTIONS && (
        <NavItem
          title="Subscriptions"
          path={ROUTE_PATH.SUBSCRIPTIONS}
          icon={<Iconify icon="streamline:subscription-cashflow" />}
        />
      )}
      {SHOW_ADMIN_USERS && (
        <NavItem
          title="Users"
          path={ROUTE_PATH.ADMIN_USERS}
          icon={<Iconify icon="mdi:users-check" />}
        />
      )}

      <StyledSubHeader component="div">Content</StyledSubHeader>
      {SHOW_CONTENT_CATEGORIES && (
        <NavItem
          title={`${CONTENT_ALIAS.CATEGORY_PLURAL}`}
          path={ROUTE_PATH.CONTENT_CATEGORIES}
          icon={<Iconify icon="iconamoon:category" />}
        />
      )}
      {SHOW_CONTENT_VIDEOS && (
        <NavItem
          title={`${CONTENT_ALIAS.VIDEO_PLURAL}`}
          path={ROUTE_PATH.CONTENT_VIDEOS}
          icon={<Iconify icon="octicon:video-16" />}
        />
      )}
      {SHOW_CONTENT_TRANSMISSION_ORGANS && (
        <NavItem
          title="Transmission Organs"
          path={ROUTE_PATH.CONTENT_TRANSMISSION_ORGANS}
          icon={<Iconify icon="covid:transmission-virus-mobile-application" />}
        />
      )}
      {SHOW_CONTENT_SEQUENCE && (
        <NavItem
          title="Sequence"
          path={ROUTE_PATH.CONTENT_SEQUENCE_VIDEOS}
          icon={<Iconify icon="fluent-mdl2:number-sequence" />}
        />
      )}
      {/* {SHOW_CONTENT_SEQUENCE && (
        <NavGroup
          title="Sequence"
          routePath={ROUTE_PATH.CONTENT_SEQUENCE}
          icon={<Iconify icon="fluent-mdl2:number-sequence" />}
          navGroup={[
            {
              title: `${CONTENT_ALIAS.VIDEO_PLURAL}`,
              slug: 'videos',
              status: 1,
            },
            { title: 'Testimonial Tabs', slug: 'testimonials/tabs', status: 1 },
            {
              title: 'Testimonial Categories',
              slug: 'testimonials/categories',
              status: 1,
            },
          ]}
        />
      )} */}

      <NavGroup
        title="Testimonials"
        routePath={ROUTE_PATH.CONTENT_TESTIMONIALS}
        icon={<Iconify icon="fluent-mdl2:office-chat" />}
        navGroup={[
          { title: 'Tabs', slug: 'tabs', status: 1 },
          { title: 'Categories', slug: 'categories', status: 1 },
          { title: 'Text Testimonials', slug: 'text-testimonials', status: 1 },
        ]}
      />

      {SHOW_VIDEO_COMMENTS && (
        <NavItem
          title="Comments"
          path={ROUTE_PATH.CONTENT_VIDEO_COMMENTS}
          icon={<Iconify icon="tabler:message-circle-cog" />}
        />
      )}

      {SHOW_NOTIFICATIONS && (
        <NavItem
          title="Notifications"
          path={ROUTE_PATH.NOTIFICATIONS}
          icon={
            <Iconify icon="material-symbols:notification-add-outline-rounded" />
          }
        />
      )}

      {SHOW_SALUTATION && (
        <NavItem
          title="Salutation"
          path={ROUTE_PATH.SALUTATION}
          icon={<Iconify icon="mdi:human-greeting-variant" />}
        />
      )}

      {(SHOW_REPORTS_OVERVIEW_VIDEOS ||
        SHOW_REPORTS_LIVE_USERS ||
        SHOW_REPORTS_WATCH_SESSIONS) && (
        <StyledSubHeader component="div">Analytics Reports</StyledSubHeader>
      )}
      {SHOW_REPORTS_OVERVIEW_VIDEOS && (
        <NavItem
          title="Video Watch Overview"
          path={ROUTE_PATH.REPORTS_OVERVIEW_VIDEOS}
          icon={<Iconify icon="ic:outline-analytics" />}
        />
      )}
      {SHOW_REPORTS_WATCH_SESSIONS && (
        <NavItem
          title="Video Watch Sessions"
          path={ROUTE_PATH.REPORTS_SESSIONS}
          icon={<Iconify icon="ic:outline-list-alt" />}
        />
      )}
      {SHOW_REPORTS_LIVE_USERS && (
        <NavItem
          title="Live Users"
          path={ROUTE_PATH.REPORTS_LIVE_USERS}
          icon={<Iconify icon="ic:round-live-tv" />}
        />
      )}
      {SHOW_REPORTS_SUBSCRIPTIONS && (
        <NavItem
          title="Subscriptions Summary"
          path={ROUTE_PATH.REPORTS_SUBSCRIPTIONS}
          icon={<Iconify icon="wpf:banknotes" />}
        />
      )}
    </List>
  </Box>
);

NavItems.propTypes = {};

export default NavItems;
