import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import Iconify from '../common/iconify.component';

const TimeLine = ({ el }) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    sx={{ fontSize: '12px' }}
  >
    <Divider width="42%" />
    {el.text}
    <Divider width="42%" />
  </Stack>
);

TimeLine.propTypes = {
  el: PropTypes.object.isRequired,
};

const CommentsLink = ({ el }) => {
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    sx={{ fontSize: '12px' }}
  >
    <Button>{el.btnText}</Button>
  </Stack>;
};

CommentsLink.propTypes = {
  el: PropTypes.object.isRequired,
};

const commetOptions = [{ title: 'Delete Comment' }];

const CommentOptions = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <InputAdornment
        position="end"
        sx={{ alignSelf: 'center' }}
        onClick={handleClick}
      >
        <IconButton
          edge="end"
          color="primary"
          sx={{ fontSize: '16px', fontWeight: 'bold' }}
        >
          <Iconify icon="ph:dots-three-vertical" />
        </IconButton>
      </InputAdornment>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Stack spacing={0.25}>
          {commetOptions.map((el) => (
            <MenuItem onClick={handleClick} sx={{ fontSize: '10px' }}>
              {el.title}
            </MenuItem>
          ))}
        </Stack>
      </Menu>
    </>
  );
};

const TextComment = ({ el }) => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      justifyContent={el.isAdmin ? 'end' : 'start'}
      alignItems="end"
    >
      {!el.isAdmin && el.profilePic && (
        <Avatar
          src={el.profilePic}
          alt={`${el.firstName} ${el.lastName}`}
          sx={{ width: 30, height: 30, marginRight: 0.75 }}
        />
      )}

      {!el.isAdmin && !el.profilePic && (
        <Avatar sx={{ width: 30, height: 30, marginRight: 0.75 }}>
          {el.firstName.charAt(0)}
        </Avatar>
      )}

      <Stack direction="row" alignItems="end">
        <Box
          px={1.5}
          py={0.5}
          sx={{
            backgroundColor: el.isAdmin
              ? theme.palette.primary.main
              : theme.palette.background.default,
            borderRadius: 4,
            width: 'max-content',
            fontSize: '14px',
          }}
        >
          {el.comment}
        </Box>
        <Box
          sx={{
            width: 'max-content',
            fontSize: '10px',
            marginLeft: '4px',
          }}
        >
          {el.createdAt}
        </Box>
      </Stack>

      <CommentOptions />
    </Stack>
  );
};

TextComment.propTypes = {
  el: PropTypes.object.isRequired,
};

const Comments = ({ comments }) => (
  <Box p={3}>
    <Stack spacing={2}>
      {comments.map((el) => {
        switch (el.type) {
          case 'date':
            return <TimeLine el={el} />;

          case 'link':
            return <CommentsLink el={el} />;

          case 'comment':
            return <TextComment el={el} />;

          default:
            return null;
        }
      })}
    </Stack>
  </Box>
);

Comments.propTypes = {
  comments: PropTypes.any.isRequired,
};

export default Comments;
