import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS, CONTENT_ALIAS } from '../const.config';

// Live users module configurations
export const LISTING_COLUMNS = [
  {
    id: 'sessionId',
    label: 'Session ID',
    width: '10%',
    align: 'left',
    dataKey: 'sessionId',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'firstName',
    label: 'First Name',
    width: '10%',
    align: 'left',
    dataKey: 'subscriberFirstName',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    width: '10%',
    align: 'left',
    dataKey: 'subscriberLastName',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'email',
    label: 'Email Address',
    width: '15%',
    align: 'left',
    dataKey: 'subscriberEmail',
    sortable: true,
    type: CELL_DATA_TYPES.EMAIL,
  },
  {
    id: 'video',
    label: `${CONTENT_ALIAS.VIDEO_SINGULAR}`,
    width: '20%',
    align: 'left',
    dataKey: 'videoTitle',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ip',
    label: 'IP Address',
    width: '10%',
    align: 'left',
    dataKey: 'ip',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'sessionStartTime',
    label: 'Session Start Time',
    width: '15%',
    align: 'left',
    dataKey: 'sessionStartedAt',
    sortable: true,
    type: CELL_DATA_TYPES.DATETIME,
  },
  {
    id: 'watchedSeconds',
    label: 'Total Watched',
    width: '10%',
    align: 'right',
    dataKey: 'totalSecondsWatch',
    sortable: true,
    type: CELL_DATA_TYPES.DURATION,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
];
