import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  Stack,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  LISTING_COLUMNS,
  LISTING_ACTIONS,
} from '../../config/module-configs/videos.config';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import {
  deleteVideoById,
  getDataList,
  getPlayableStream,
  syncVideoCDNStatus,
  toggleStatus,
} from '../../services/content.service';
import {
  ACTIONS,
  CONTENT_ALIAS,
  MULTIPLE_CAT_PER_VIDEO,
} from '../../config/const.config';
import AddEditVideos from './add-edit-videos.component';
import ConfirmPopup from '../common/confirm-popup.component';
import SnackbarInfo from '../common/snackbar-info.component';
import ViewVideos from './view-videos.component';
import AssignCategoryVideos from './assign-category-videos.component';
import VideoPreview from './videos-preview.component';
import ImagePreview from './image-preview.component';
import UploadAssets from './upload-assets.component';
import TranslateVideos from './translate-videos.component';
import ROUTE_PATH from '../../config/routes.config';

const ListVideos = () => {
  const navigate = useNavigate();

  const columns = LISTING_COLUMNS;
  const actions = LISTING_ACTIONS;

  const renderActions = [];
  actions.forEach((item) => {
    if (MULTIPLE_CAT_PER_VIDEO && item.action === 'assign_books') {
      renderActions.push(item);
    } else if (item.action !== 'assign_books') {
      renderActions.push(item);
    }
  });

  const searchVideosRef = useRef(null);
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    q: null,
    error: false,
    loading: true,
    searchValue: '',
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [videoPlayer, setVideoPlayer] = useState({
    show: false,
    videoUrl: '',
    title: '',
  });
  const [imagePreview, setImagePreview] = useState({
    show: false,
    imageUrl: '',
    title: '',
  });

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleActionSelect = (selectedAction, selectedData) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    if (selectedAction === ACTIONS.VIEW_VIDEO_COMMENTS.value) {
      navigate(
        `${ROUTE_PATH.CONTENT_VIDEO_COMMENTS}/${selectedData.programId}/${selectedData.id}`
      );
    } else {
      setDoAction({
        ...doAction,
        data: selectedData || null,
        action: selectedAction || null,
      });
    }
  };

  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });

    setImagePreview({
      ...imagePreview,
      show: false,
    });
  };

  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }

    handleActionCancel();
    handleRefreshData();
  };

  const handleDeleteData = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    deleteVideoById(doAction.data.id)
      .then(() => {
        handleActionSuccess(
          `${CONTENT_ALIAS.VIDEO_SINGULAR} deleted successfully.`
        );
        handleActionCancel();
      })
      .catch((e) => {
        const message =
          e.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        handleActionCancel();
      });
  };

  const handleClickEvent = (video) => {
    const uri = `videoID=${video.id}`;

    getPlayableStream(uri)
      .then((res) => {
        setVideoPlayer({
          ...videoPlayer,
          show: true,
          title: video.title,
          videoUrl: res.data.videoUrl,
        });
      })
      .catch(() =>
        setTimeout(() => {
          setVideoPlayer({
            ...videoPlayer,
            show: false,
            title: '',
            videoUrl: '',
          });
        }, 5000)
      );
  };

  const handleImagePreview = (rowData) => {
    setImagePreview({
      ...imagePreview,
      show: true,
      imageUrl: rowData.thumbnail,
      title: rowData.title,
    });
  };

  const handleSyncCDNStatus = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    syncVideoCDNStatus()
      .then((res) => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: `${res.data?.updatedRowsCount} data updated.`,
        });
        setOptions({ ...options, reloadCounter: options.reloadCounter + 1 });
      })
      .catch((e) => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: e.response?.data?.message,
        });
      });
  };

  const handleToggleStatus = (r, value) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      id: r.id,
      status: value ? 0 : 1,
      type: 'video',
    };

    toggleStatus(payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'Status updated.',
        });
      })
      .catch((e) => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: e.response?.data?.message,
        });
      });
  };

  const handleSearchChange = (e) => {
    setOptions({
      ...options,
      searchValue: e.target.value || '',
    });
  };

  const handleSearchVideos = () => {
    setOptions({
      ...options,
      q: searchVideosRef.current.value,
      reloadCounter: options.reloadCounter + 1,
      page: 0,
    });
  };

  const dataListAPICall = () => {
    let uri = 'videos';
    uri += `?page=${options.page + 1}`;
    uri += `&perPage=${options.rowsPerPage}`;
    if (options.sortBy && options.sortOrder) {
      uri += `&sortBy=${options.sortBy}`;
      uri += `&sortOrder=${options.sortOrder}`;
    }
    if (options.q) {
      uri += `&q=${options.q}`;
    }

    setOptions({ ...options, loading: true, searchValue: '' });

    getDataList(uri)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  };

  useEffect(() => {
    dataListAPICall(options);
  }, [options.reloadCounter]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <div>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Iconify icon="ic:round-add" />}
            onClick={() => handleActionSelect(ACTIONS.CREATE.value, null)}
          >
            Create New
          </Button>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Iconify icon="fe:sync" />}
            sx={{
              marginLeft: '5px',
            }}
            onClick={() => handleSyncCDNStatus()}
          >
            Sync CDN Status
          </Button>

          <TextField
            label={`Search ${CONTENT_ALIAS.VIDEO_PLURAL}`}
            type="text"
            inputRef={searchVideosRef}
            onChange={handleSearchChange}
            sx={{
              width: '200px',
              marginLeft: '5px',
              '.MuiInputLabel-root.MuiInputLabel-shrink.MuiFormLabel-filled': {
                top: '0px',
              },
              '.MuiInputLabel-root': {
                top: '-8px',
              },
            }}
            InputProps={{
              style: {
                height: '2.25em',
              },
              endAdornment: (
                <InputAdornment position="end" onClick={handleSearchVideos}>
                  <IconButton edge="end" color="primary">
                    <Iconify icon="fe:search" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: !(
                searchVideosRef.current?.value === '' ||
                searchVideosRef.current === null
              ),
            }}
          />
        </div>
        <div>
          <Button
            color="inherit"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
          >
            Refresh
          </Button>
        </div>
      </Stack>

      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={renderActions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          onAction={handleActionSelect}
          onCustomClick={handleClickEvent}
          handleImagePreview={handleImagePreview}
          handleToggleStatus={handleToggleStatus}
        />
      </Card>

      {doAction.action === ACTIONS.CREATE.value && (
        <AddEditVideos
          title={`Add New ${CONTENT_ALIAS.VIDEO_SINGULAR}`}
          dataId={0}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          options={options}
        />
      )}

      {doAction.action === ACTIONS.EDIT.value && (
        <AddEditVideos
          title={`Edit ${CONTENT_ALIAS.VIDEO_SINGULAR} Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          options={options}
        />
      )}

      {doAction.action === ACTIONS.VIEW.value && (
        <ViewVideos
          title={`${CONTENT_ALIAS.VIDEO_SINGULAR} Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
        />
      )}

      {doAction.action === ACTIONS.DELETE.value && (
        <ConfirmPopup
          title={`Delete ${CONTENT_ALIAS.VIDEO_SINGULAR}`}
          message={`Do you want to delete '${doAction.data.title}' ${CONTENT_ALIAS.VIDEO_SINGULAR}? You can not undo this action!`}
          onCancel={handleActionCancel}
          onSuccess={handleDeleteData}
        />
      )}

      {doAction.action === ACTIONS.ASSIGN_CATEGORIES.value && (
        <AssignCategoryVideos
          title={`Assign ${CONTENT_ALIAS.CATEGORY_PLURAL} to ${doAction.data.title} ${CONTENT_ALIAS.VIDEO_SINGULAR}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          options={options}
        />
      )}

      {doAction.action === ACTIONS.UPLOAD_MP4.value && (
        <UploadAssets
          title={`Upload MP4 - ${doAction.data.title}`}
          dataId={doAction.data.id}
          dataTitle={doAction.data.title}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.TRANSLATE.value && (
        <TranslateVideos
          title={`Translate ${CONTENT_ALIAS.VIDEO_SINGULAR} Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {videoPlayer.show && (
        <VideoPreview
          videoPlayer={videoPlayer}
          setVideoPlayer={setVideoPlayer}
        />
      )}

      {imagePreview.show && (
        <ImagePreview
          title={`Thumbnail - ${imagePreview.title}`}
          onCancel={handleActionCancel}
          imageUrl={imagePreview.imageUrl}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

export default ListVideos;
